<template>
  <b-card title="Arquivo">

    <!-- User Info: Input Fields -->
    <b-form>
      <validation-observer ref="form">

        <b-row>
          <!-- Field: Username -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Nome"
              label-for="name"
            >
              <validation-provider
                #default="{ errors }"
                name="Nome"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="utilizador.nome"
                  maxlength="100"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Email -->
          <b-col
            cols="12"
            md="5"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required"
              >
                <b-form-input
                  id="email"
                  v-model="utilizador.email"
                  type="email"
                  maxlength="256"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="username"
              label-for="username"
            >
              <validation-provider
                #default="{ errors }"
                name="username"
                rules="required"
              >
                <b-form-input
                  id="username"
                  v-model="utilizador.username"
                  maxlength="20"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Field: Role -->

        <b-row>
          <!-- new password -->
          <b-col md="6">
            <b-form-group
              label-for="account-new-password"
              label="Password"
            >
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-new-password"
                    v-model="utilizador.password"
                    :type="passwordFieldTypeNew"
                    name="new-password"
                    placeholder="Password"
                    maxlength="70"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="6">
            <b-form-group
              label-for="account-retype-new-password"
              label="Repetir Password"
            >
              <validation-provider
                #default="{ errors }"
                name="Repetir Password"
                rules="required"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-retype-new-password"
                    v-model="utilizador.repassword"
                    :type="passwordFieldTypeRetype"
                    name="retype-password"
                    placeholder="Password"
                    maxlength="70"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconRetype"
                      class="cursor-pointer"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        <!--/ retype password -->
        </b-row>
        <b-row>
          <!-- Field: Status -->
          <b-col
            md="4"
          >
            <b-form-group
              label="Estado"
              label-for="user-status"
            >
              <validation-provider
                #default="{ errors }"
                name="Estado"
                rules="required"
              >
                <div class="demo-inline-spacing">
                  <b-form-radio
                    v-model="utilizador.ativo"
                    name="some-radios"
                    value="1"
                  >
                    Ativo
                  </b-form-radio>
                  <b-form-radio
                    v-model="utilizador.ativo"
                    name="some-radios"
                    value="0"
                  >
                    Inativo
                  </b-form-radio>

                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider></b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="12"
            class="mt-2"
          >
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              @click="switchLoc()"
            >
              Guardar
            </b-button>
            <b-button
              variant="outline-secondary"
              type="reset"
            >
              Cancelar
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </b-form>

  </b-card>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput,
  BCard,
  BForm,
  BInputGroup,
  BInputGroupAppend,
  BButton,
  BFormRadio,
} from 'bootstrap-vue'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import pt from 'vee-validate/dist/locale/pt_PT'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormRadio,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },

  props: {
    utilizador: Object,
    perfis: Object,
  },
  data() {
    return {
      required,
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('jwt')}`,
      },
    }
  },
  computed: {
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  created() {
    localize('pt', pt)
  },
  methods: {
    switchLoc() {
      this.$refs.form.validate().then(valid => {
        if (!valid) {
          this.erroGravacao('danger', 'CheckIcon')
        } else {
          this.saveUser()
        }
      })
    },
    saveUser() {
      if (this.utilizador.password === this.utilizador.repassword) {
        axios.get(`/api/v1/users/email/${this.utilizador.email}`)
          .then(res => {
            if (res.status === 200) {
              this.duplicado('danger', 'CheckIcon')
            }
          }).catch(error => {
            if (error.response.status === 404) {
              this.guardar()
            }
          })
      } else {
      }
    },
    guardar() {
      axios.post('/api/v1/users', this.utilizador)
        .then(res => {
          this.perfis.idUtilizador = res.data.id
          this.saveUserProfile()
          this.sucesso('sucess', 'CheckIcon')
        })
    },
    duplicado(variant, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Erro',
          icon,
          text: 'Email já existe !',
          variant,
        },

      })
    },
    sucesso(variant, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'sucesso',
          icon,
          text: 'Sucesso !',
          variant,
        },

      })
    },
    saveUserProfile() {
      axios.post('/api/v1/userProfiles/profiles', this.perfis)
        .then()
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    erroGravacao(variant, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Erro',
          icon,
          text: 'O Registo apresenta erros !',
          variant,
        },

      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
